import React from 'react';
import { Table, Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import JKAffiliateSignup from './JKAffiliateSignup';

const JKAffiliateSignupsList = ({ signups, nextPage, loading, stepForward }) => {
  const { t } = useTranslation('affiliate');
  return (
    <Table striped bordered className="fs--1" data-testid="affiliateSignupList">
      <thead className="bg-200 text-900">
        <tr>
          <th width="35%" scope="col">
            {t('signups.list.header.date')}
          </th>
          <th width="35%" scope="col" className="text-center">
            {t('signups.list.header.visits')}
          </th>
          <th scope="col" className="text-center">
            {t('signups.list.header.signups')}
          </th>
        </tr>
      </thead>
      <tbody>
        {signups.map((signup, index) => (
          <JKAffiliateSignup key={index} signup={signup} />
        ))}
      </tbody>
      <div>
        {nextPage && nextPage > 0 && !loading && <button onClick={stepForward}>{t('signups.load_more')}</button>}
      </div>
    </Table>
  );
};

export default JKAffiliateSignupsList;
