import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '@farfetch/react-context-responsive';
import { useParams } from 'react-router-dom';
import { Card, CardBody, Row, Col } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { getUserDetail, postUserEvent, userOpenedJamTrackWebPlayer } from '../../helpers/rest';
import JKJamTrackPlayer from './JKJamTrackPlayer';
import JKMyJamTrackMixes from './JKMyJamTrackMixes';
import JKCreateCustomMix from './JKCreateCustomMix';
import { useAuth } from '../../context/UserAuth';

import { fetchJamTrack } from '../../store/features/jamTrackSlice';
import { useDispatch, useSelector } from 'react-redux';

const JKJamTrack = () => {
  console.log('JKJamTrack rendering');

  const { t } = useTranslation('jamtracks');
  const { greaterThan } = useResponsive();
  const { id } = useParams();

  const { currentUser } = useAuth();

  const dispatch = useDispatch();

  const jamTrack = useSelector(state => state.jamTrack.jamTrack);
  const jamTrackLoadingStatus = useSelector(state => state.jamTrack.status);

  const fetchJamTrackRecord = () => {
    dispatch(fetchJamTrack({ id }));
  };

  const fetchUserDetail = async () => {
    try {
      const userId = currentUser.id;
      const resp = await getUserDetail({ id: userId });
      const data = await resp.json();
      console.log('user detail', data);
      await postUserEvent({ name: 'jamtrack_web_player_open' });
      if (!data.first_opened_jamtrack_web_player) {
        setTimeout(async () => {
          await userOpenedJamTrackWebPlayer();
        }, 15000);
      }
    } catch (error) {
      console.log('Error when fetching user detail', error);
    }
  };

  useEffect(() => {
    if (currentUser && jamTrack) {
      fetchUserDetail();
    }
  }, [currentUser, jamTrack]);

  useEffect(() => {
    fetchJamTrackRecord();
  }, [id]);

  return (
    <>
      {jamTrackLoadingStatus === 'loading' || jamTrackLoadingStatus == 'idel' ? (
        <div>Loading...</div>
      ) : Object.keys(jamTrack).length ? (
        <Row>
          <Col sm={12} md={4}>
            <Card className="mx-auto mb-4">
              <FalconCardHeader title={t('jamtrack.player.title')} titleClass="font-weight-semi-bold" />
              <CardBody className="pt-3">{jamTrack && <JKJamTrackPlayer />}</CardBody>
            </Card>
            <Card className="mx-auto">
              <FalconCardHeader title={t('jamtrack.my_mixes.title')} titleClass="font-weight-semi-bold" />
              <CardBody className="pt-3">{jamTrack && <JKMyJamTrackMixes />}</CardBody>
            </Card>
          </Col>
          <Col sm={12} md={4}>
            <Card className="mx-auto">
              <FalconCardHeader title={t('jamtrack.create_mix.title')} titleClass="font-weight-semi-bold" />
              <CardBody className="pt-3">{jamTrack && <JKCreateCustomMix />}</CardBody>
            </Card>
          </Col>
          <Col sm={12} md={4}>
            <Card className="mx-auto">
              <FalconCardHeader title={t('jamtrack.help_resources.title')} titleClass="font-weight-semi-bold" />
              <CardBody className="pt-3">
                <div className="mb-3">
                  <div>
                    <a target="_blank" href="https://jamkazam.freshdesk.com/support/solutions/articles/66000501472">
                      {t('jamtrack.help_resources.using_overview.title')}
                    </a>
                  </div>
                  {t('jamtrack.help_resources.using_overview.description')}
                </div>
                <div className="mb-3">
                  <div>
                    <a target="_blank" href="https://jamkazam.freshdesk.com/support/solutions/articles/66000125792">
                      {t('jamtrack.help_resources.using_mac_windows.title')}
                    </a>
                  </div>
                  {t('jamtrack.help_resources.using_mac_windows.description')}
                </div>
                <div className="mb-3">
                  <div>
                    <a target="_blank" href="https://www.jamkazam.com/client#/jamtrack">
                      {t('jamtrack.help_resources.jamtracks_home.title')}
                    </a>
                  </div>
                  {t('jamtrack.help_resources.jamtracks_home.description')}
                </div>
                <div className="mb-3">
                  <div>
                    <a target="_blank" href={`https://www.jamkazam.com/client?artist=${encodeURIComponent(
                        jamTrack.original_artist
                      )}#/jamtrack/search`}
                    >
                      {t('jamtrack.help_resources.see_more.title')}
                    </a>
                  </div>
                  {t('jamtrack.help_resources.see_more.description')}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default JKJamTrack;
