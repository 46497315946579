import React from 'react';
import { Row, Col, Table, Button } from 'reactstrap';
import JKJamTrackPreview from './JKJamTrackPreview';
import JKJamTrackPurchaseButton from './JKJamTrackPurchaseButton';
import { JamTrackPreviewProvider } from '../../context/JamTrackPreviewContext';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const JKJamTracksList = ({ selectedType, searchTerm, jamTracks, nextOffset, onNextPage }) => {
  const { t } = useTranslation('jamtracks');
  return (
    <>
      {selectedType && searchTerm.length && jamTracks.length > 0 ? (
        <Row className="mb-2">
          <Col>
            <strong>
              {t('search.search_results.for_selection')} {selectedType} "{searchTerm}"
            </strong>
          </Col>
        </Row>
      ) : (
        searchTerm.length > 0 &&
        jamTracks.length > 0 && (
          <Row className="mb-2">
            <Col>
              <strong>{t('search.search_results.for_search_term')} "{searchTerm}"</strong>
            </Col>
          </Row>
        )
      )}
      {jamTracks.length > 0 && (
        <Row>
          <Col>
            <Table striped bordered className="fs-0" data-testid="jamtracks-table">
              <thead className="bg-200 text-900">
                <tr>
                  <th width="30%">{t('search.list.song')}</th>
                  <th width="55%">{t('search.list.tracks')}</th>
                  <th className='text-center' align='center'>{t('search.list.shop')} </th>
                </tr>
              </thead>
              <tbody>
                <JamTrackPreviewProvider>
                  {jamTracks.map((jamTrack, index) => (
                    <tr key={`jamtrck-preview-row-${jamTrack.id}`}>
                      <td className="track-name-col">
                        {jamTrack.name} <br />
                        <span className='fs--1'>by {jamTrack.original_artist}</span>
                      </td>
                      <td className="track-tracks-col fs--1">
                        <JKJamTrackPreview jamTrack={jamTrack} />
                      </td>
                      <td className="purchase-button-col text-center" align='center'>
                        <JKJamTrackPurchaseButton jamTrack={jamTrack} />
                      </td>
                    </tr>
                  ))}
                </JamTrackPreviewProvider>
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
      {nextOffset && (
        <Row>
          <Col>
            <Button color="primary" onClick={onNextPage} data-testid="moreBtn">
            {t('search.list.load_more')}
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

JKJamTracksList.propTypes = {
  selectedType: PropTypes.string || null,
  searchTerm: PropTypes.string,
  jamTracks: PropTypes.array,
  nextOffset: PropTypes.number,
  onNextPage: PropTypes.func
};

JKJamTracksList.defaultProps = {
  selectedType: null,
  searchTerm: '',
  jamTracks: [],
  nextOffset: null,
  onNextPage: () => {}
};

export default JKJamTracksList;
