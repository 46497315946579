import React, { useEffect, useState } from 'react';
import jkCustomUrlScheme from '../../helpers/jkCustomUrlScheme';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Row, Col } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';

const JKCustomUrlSchemaHandle = () => {
  const [urlScheme, setUrlScheme] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const queryStr = window.location.search;
    if (!queryStr) return;
    const urlParams = new URLSearchParams(queryStr);
    const action = urlParams.get('act');
    const params = urlParams.get('p');
    const appUrl = jkCustomUrlScheme(action, params);
    setUrlScheme(appUrl);
  }, []);

  useEffect(() => {
    if (urlScheme) {
      console.log('opening custom url', urlScheme);
      window.open(urlScheme, '_self');
    }
  }, [urlScheme]);

  return (
    <div>
      <Card>
        <FalconCardHeader title={t('new.page_title', { ns: 'sessions' })} titleClass="font-weight-bold" />
        <CardBody className="pt-0">
          <Row>
            <Col>
              <div className="mt-8 text-center mb-8">
                {urlScheme && (
                  <>
                    <p>
                      Click <strong>Open JamKazam.app</strong> on the dialog shown by the browser. <br />
                      If you don't see the dialog, click <strong>Lauhch JamKazam App</strong> below.
                    </p>
                    <div>
                      <a href={urlScheme} className='btn btn-primary'>Lauhch JamKazam App</a>
                    </div>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default JKCustomUrlSchemaHandle;
