import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Row, Col } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { useTranslation } from 'react-i18next';
import JKJamTracksAutoComplete from './JKJamTracksAutoComplete';
import { getJamTracks, getJamTrackArtists, autocompleteJamTracks } from '../../helpers/rest';
import JKJamTrackArtists from './JKJamTrackArtists';
import JKJamTracksList from './JKJamTracksList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useShoppingCart } from '../../hooks/useShoppingCart';
import { useResponsive } from '@farfetch/react-context-responsive';

const JKJamTracksFilter = () => {
  const { t } = useTranslation('jamtracks');
  const [jamTracks, setJamTracks] = useState([]);
  //const [artists, setArtists] = useState([]);
  const [selected, setSelected] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  //const [showArtists, setShowArtists] = useState(false);
  const [nextOffset, setNextOffset] = useState(null);
  const [autoCompleteInputValue, setAutoCompleteInputValue] = useState('');
  const [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const { shoppingCart } = useShoppingCart();
  const { greaterThan } = useResponsive();

  // useEffect(() => {
  //   setCartItems(shoppingCart);
  // }, []);

  useEffect(() => {
    if (selected) {
      setSearchTerm(selected.type === 'artist' ? selected.original_artist : selected.name);
    }
  }, [selected]);

  const queryOptions = selected => {
    const options = {
      per_page: PER_PAGE,
      page: page
    };

    if (typeof selected === 'string') {
      options.search = selected;
      return options;
    }

    if (selected.type === 'artist') {
      options.artist = selected.original_artist;
    } else {
      options.song = selected.name;
    }

    return options;
  };

  const handleOnSelect = async selected => {
    setPage(1);
    //setArtists([]);
    setJamTracks([]);
    setSearchTerm('');
    //setShowArtists(false);
    setSelected(selected);
    const params = queryOptions(selected);
    await fetchJamTracks(params);
  };

  const handleOnEnter = async queryStr => {
    setPage(1);
    //setArtists([]);
    setJamTracks([]);
    setSelected(x => null);
    setSearchTerm(queryStr);
    //setShowArtists(false);
    //fetchArtists(queryStr);
    const params = queryOptions(queryStr);
    console.log('handleOnEnter _params', params, selected);
    await fetchJamTracks(params);
  };

  // const handleOnSelectArtist = async artist => {
  //   setPage(1);
  //   const selectedOpt = {
  //     type: 'artist',
  //     original_artist: artist.original_artist
  //   };
  //   setShowDropdown(false);
  //   setAutoCompleteInputValue('');
  //   await handleOnSelect(selectedOpt);
  // };

  const handleOnNextJamTracksPage = async () => {
    const currentQuery = selected ? selected : searchTerm;
    const params = queryOptions(currentQuery);
    await fetchJamTracks(params);
  };

  const fetchJamTracks = async options => {
    try {
      const resp = await getJamTracks(options);
      const data = await resp.json();
      console.log('jamtracks', data);
      setJamTracks(prev => [...prev, ...data.jamtracks]);
      setNextOffset(data.next);
    } catch (error) {
      console.error('error', error);
    }
  };

  // const fetchArtists = query => {
  //   const options = {
  //     limit: 100
  //   };
  //   options.artist_search = query;

  //   getJamTrackArtists(options)
  //     .then(resp => {
  //       return resp.json();
  //     })
  //     .then(data => {
  //       console.log('artists', data);
  //       setArtists(data.artists);
  //       setShowArtists(true);
  //     })
  //     .catch(error => {
  //       console.error('error', error);
  //     });
  // };

  return (
    <Card>
      <FalconCardHeader title={t('search.page_title')} titleClass="font-weight-bold" />
      <CardBody className="pt-3">
        {!greaterThan.sm && (
          <Row>
            <JKJamTrackFilterLinks shoppingCart={shoppingCart} wrapperClassNames='d-flex flex-column' shoppingCartClassNames="col mt-1 mb-2" downloadLinkClassNames='col' />
          </Row>
        )}
        <Row>
          <Col>
            <JKJamTracksAutoComplete
              fetchFunc={autocompleteJamTracks}
              onSelect={handleOnSelect}
              onEnter={handleOnEnter}
              showDropdown={showDropdown}
              setShowDropdown={setShowDropdown}
              inputValue={autoCompleteInputValue}
              setInputValue={setAutoCompleteInputValue}
              inputPlaceholder={t('search.search_input.placeholder')}
            />
          </Col>
          {greaterThan.sm && (
            <Col>
            <JKJamTrackFilterLinks
              shoppingCart={shoppingCart}
              wrapperClassNames="d-flex justify-content-end"
              shoppingCartClassNames="ml-3 mr-1"
            />
            </Col>
          )}
        </Row>

        {/* <div className="mb-3">
          <JKJamTrackArtists
            artists={artists}
            searchTerm={searchTerm}
            onSelect={handleOnSelectArtist}
            showArtists={showArtists}
          />
        </div> */}
        <JKJamTracksList
          selectedType={selected?.type}
          searchTerm={searchTerm}
          jamTracks={jamTracks}
          nextOffset={nextOffset}
          onNextPage={handleOnNextJamTracksPage}
        />
      </CardBody>
    </Card>
  );
};

const JKJamTrackFilterLinks = ({ shoppingCart, wrapperClassNames, shoppingCartClassNames, downloadLinkClassNames }) => {
  return (
    <div className={wrapperClassNames}>
      <div className={downloadLinkClassNames}>
        Download JamTracks catalog as a{' '}
        <a
          data-testid="download-pdf"
          href="https://s3.amazonaws.com/jamkazam-public/public/lists/all-jamkazam-jamtracks.pdf"
          target='_blank'
        >
          <strong>PDF file</strong>
        </a>
      </div>
      {shoppingCart.length > 0 && (
        <div className={shoppingCartClassNames}>
          <Link to="shopping-cart" className="btn btn-primary btn-sm">
            <FontAwesomeIcon icon="shopping-cart" className="mr-1" />
            View Cart ({shoppingCart.length})
          </Link>
        </div>
      )}
    </div>
  );
};

export default JKJamTracksFilter;
