import React from 'react';
import { Card, CardBody } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '@farfetch/react-context-responsive';
import { Link } from 'react-router-dom';

const JKAffiliateProgram = () => {
  const { t } = useTranslation('affiliate');
  const { greaterThan } = useResponsive();
  return (
    <Card style={{ width: greaterThan.sm ? "75%" : '100%'}} className='mx-auto'>
      <FalconCardHeader title={t('program.page_title')} titleClass="font-weight-semi-bold" />
      <CardBody className="pt-3">
        <div>
          <p>{t('program.paragraph1')}</p>
          <p>{t('program.paragraph2')}</p>
          <p>{t('program.paragraph3')}</p>
          <p>
            {t('program.paragraph4-1')}
            <Link to="/affiliate/agreement"><strong>{t('program.click-here')}</strong></Link>
            {t('program.paragraph4-2')}
            <Link to="/affiliate/links"><strong>{t('program.click-here')}</strong></Link>
            {t('program.paragraph4-3')}
          </p>
        </div>
      </CardBody>
    </Card>
  );
};

export default JKAffiliateProgram;
