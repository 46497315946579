import React, { useState, useEffect } from 'react';

import { Card, CardBody, Row, Col } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { useTranslation } from 'react-i18next';
import { getAffiliateSignups } from '../../helpers/rest';
import { isIterableArray } from '../../helpers/utils';
import Loader from '../common/Loader';
import JKAffiliateSignupsList from './JKAffiliateSignupsList';
import JKAffiliateSignupsSwiper from './JKAffiliateSignupsSwiper';
import { useResponsive } from '@farfetch/react-context-responsive';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const JKAffiliateSignups = () => {
  const { t } = useTranslation('affiliate');
  const { greaterThan } = useResponsive();
  const [signups, setSignups] = useState([]);
  const [nextPage, setNextPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchSignups = () => {
    // fetch signups
    setLoading(true);
    const options = {
      page: nextPage,
      per_page: 10
    };
    getAffiliateSignups(options)
      .then(resp => {
        return resp.json();
      })
      .then(data => {
        if(data.traffics){
          const trafficByMonth = signupsByMonth(data.traffics);
          setSignups(trafficByMonth);
          setNextPage(data.next);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const stepForward = () => {
    setNextPage(prev => prev + 1);
    fetchSignups();
  };

  useEffect(() => {
    fetchSignups();
  }, []);

  function signupsByMonth(data) {
    var item,
      i = 0,
      groups = {},
      output = [],
      date,
      year,
      month,
      key,
      monthNames = [
        t('month_name.january', { ns: 'common' }),
        t('month_name.february', { ns: 'common' }),
        t('month_name.march', { ns: 'common' }),
        t('month_name.april', { ns: 'common' }),
        t('month_name.may', { ns: 'common' }),
        t('month_name.june', { ns: 'common' }),
        t('month_name.july', { ns: 'common' }),
        t('month_name.august', { ns: 'common' }),
        t('month_name.september', { ns: 'common' }),
        t('month_name.october', { ns: 'common' }),
        t('month_name.november', { ns: 'common' }),
        t('month_name.december', { ns: 'common' })
      ];

    while ((item = data[i++])) {
      date = new Date(item.day);
      year = date.getFullYear();
      month = date.getMonth();
      key = monthNames[month] + ' ' + year;

      groups[key] || (groups[key] = []); // exists OR create []
      groups[key].push(item);
    }

    for (var key in groups) {
      var sumVisits = groups[key].reduce(function(s, a) {
        return s + a.visits;
      }, 0);

      var sumSignups = groups[key].reduce(function(s, a) {
        return s + a.signups;
      }, 0);

      output.push({ month: key, signups: sumSignups, visits: sumVisits });
    }

    return output;
  }

  return (
    <Card style={{ width: greaterThan.sm ? '50%' : '100%' }} className="mx-auto affiliate-links">
      <FalconCardHeader title={t('signups.page_title')} titleClass="font-weight-semi-bold" />
      <CardBody className="pt-3 pb-5">
        {loading ? (
          <Loader />
        ) : isIterableArray(signups) ? (
          <>
            {greaterThan.sm ? (
              <Row className="mb-3 justify-content-between d-none d-md-block">
                <div className="table-responsive-xl px-2">
                  <JKAffiliateSignupsList
                    signups={signups}
                    nextPage={nextPage}
                    loading={loading}
                    stepForward={stepForward}
                  />
                </div>
              </Row>
            ) : (
              <Row className="swiper-container d-block d-md-none" data-testid="affiliateSignupsSwiper">
                <JKAffiliateSignupsSwiper signups={signups} />
              </Row>
            )}
          </>
        ) : (
          <Row className="p-card">
            <Col>
              {/* <Alert color="info" className="mb-0"> */}
                {t('signups.no_signup_data')}
                <Link to="/affiliate/links">{t('signups.share_affiliate_links')}</Link>
                {t('signups.with_your_audience')} 
              {/* </Alert> */}
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default JKAffiliateSignups;
