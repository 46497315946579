import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JKInstrumentIcon from '../profile/JKInstrumentIcon';
import { Howl } from 'howler';
import { useJamTrackPreview } from '../../context/JamTrackPreviewContext';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

const JKJamTrackTrack = ({ track }) => {
  console.log('debug JKTrackPlayPause track');
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [trackInfo, setTrackInfo] = useState(null);
  const [trackSound, setTrackSound] = useState(null);
  const { currentPlayTrackId, setCurrentPlayTrackId } = useJamTrackPreview();

  useEffect(() => {
    if (!track) {
      return;
    }
    const info = {
      id: track.id,
      instrumentId: 'other',
      instrumentDescription: 'Master Mix',
      part: ''
    };

    if (track.instrument) {
      info['instrumentId'] = track.instrument.id;
      info['instrumentDescription'] = track.instrument.description;
    }

    if (track.track_type === 'Track') {
      if (track.part && track.part !== info['instrumentDescription']) {
        info['part'] = `(${track.part})`;
      }
    } else if (track.track_type === 'Master' && track.part) {
      info['instrumentDescription'] = track.part;
    }

    let no_audio = true;
    let urls = [];
    if (track.preview_mp3_url) {
      urls.push(track.preview_mp3_url);
      if (track.preview_ogg_url != null) {
        urls.push(track.preview_ogg_url);
      }
      no_audio = false;
    }
    info['urls'] = urls;
    info['no_audio'] = no_audio;

    setTrackInfo(info);
  }, [track]);

  useEffect(() => {
    if (!trackInfo || !trackSound) {
      return;
    }
    if (trackInfo.id !== currentPlayTrackId) {
      trackSound.pause();
      setIsPlaying(false);
    }
  }, [currentPlayTrackId]);

  const togglePlay = () => {
    if (trackSound) {
      if (isPlaying) {
        trackSound.pause();
      } else {
        trackSound.play();
        setCurrentPlayTrackId(trackInfo.id);
      }
      setIsPlaying(!isPlaying);
    } else {
      if (!trackInfo || Object.keys(trackInfo).length === 0 || trackInfo.no_audio || trackInfo.urls.length === 0) {
        return;
      }
      setIsLoading(true);
      const sound = new Howl({
        src: trackInfo.urls,
        html5: true,
        format: ['mp3', 'ogg'],
        preload: true,
        autoplay: false,
        loop: false,
        volume: 1.0,
        onend: () => {
          setIsPlaying(false);
        },
        onload: () => {
          setIsLoaded(true);
          setIsLoading(false);
          sound.play();
          setIsPlaying(true);
          setTrackSound(sound);
          setCurrentPlayTrackId(trackInfo.id);
        },
        onloaderror: (id, error) => {
          console.error('onloaderror', error);
          setIsLoading(false);
        }
      });
    }
  };

  return (
    <div className="mb-1 d-flex align-items-center jamtrack-track">
      <span className="mr-1">
        <FontAwesomeIcon icon={isPlaying ? 'pause-circle' : 'play-circle'} size="2x" onClick={togglePlay} />
      </span>
      {trackInfo && (
        <>
          <span className="mr-1 pb-1">
            <JKInstrumentIcon instrumentId={trackInfo.instrumentId} instrumentName={trackInfo.instrumentDescription} />
          </span>
          <span>
            {trackInfo.instrumentDescription} {trackInfo.part}
          </span>
          {isLoading && (
            <span className="ml-1">
              <Spinner color="primary" size="sm">
                Loading...
              </Spinner>
            </span>
          )}
        </>
      )}
    </div>
  );
};

JKJamTrackTrack.propTypes = {
  track: PropTypes.object.isRequired
};

export default JKJamTrackTrack;
