import React, { Fragment, useState } from 'react';
import { Row, Col, Container } from 'reactstrap';
import JKJamTrackTrack from './JKJamTrackTrack';
import PropTypes from 'prop-types';

const JKJamTrackPreview = ({ jamTrack }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleMoreTracks = (e) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  return (
    <Container>
      <Row>
        {jamTrack.tracks.filter(t => t.track_type !== 'Click').map((track, index) => (
          <Fragment key={track.id} >
            <Col className={ index + 1 > 6 && !expanded ? 'd-none' : null}>
              <JKJamTrackTrack track={track} />
            </Col>
            {(index + 1) % 2 === 0 && <div className="w-100" />}
            {(index + 1) === jamTrack.tracks.length && (index + 1) % 2 !== 0 && <div className="col" />}
          </Fragment>
        ))}
      </Row>
      {jamTrack.tracks.length > 6 && (
        <Row>
          <Col>
            <a href="#" onClick={toggleMoreTracks}>{expanded ? 'Show fewer tracks' : 'Show all tracks'}</a>
          </Col>
        </Row>
      )}
    </Container>
  );
};

JKJamTrackPreview.propTypes = {
  jamTrack: PropTypes.object.isRequired
};

export default JKJamTrackPreview;
