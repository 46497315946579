import { error } from 'is_js';
import apiFetch from './apiFetch';

export const getMusicians = page => {
  return new Promise((resolve, reject) => {
    apiFetch(`/search/musicians?results=true`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

// export const getPeople = (page) => {
//   return new Promise((resolve, reject) => {
//     apiFetch(`/filter?page=${page}`)
//     .then(response => resolve(response))
//     .catch(error => reject(error))
//   })
// }

export const getPersonById = id => {
  return new Promise((resolve, reject) =>
    apiFetch(`/users/${id}/profile`)
      .then(response => resolve(response))
      .catch(error => reject(error))
  );
};

export const getUserDetail = options => {
  const { id, ...rest } = options;
  return new Promise((resolve, reject) =>
    apiFetch(`/users/${id}?${new URLSearchParams(rest)}`)
      .then(response => resolve(response))
      .catch(error => reject(error))
  );
};

export const getPeople = ({ data, offset, limit } = {}) => {
  return new Promise((resolve, reject) => {
    apiFetch(`/filter?offset=${offset}&limit=${limit}`, {
      method: 'POST',
      body: JSON.stringify(data)
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getPeopleByIds = ({ userId, ids }) => {
  return new Promise((resolve, reject) => {
    apiFetch(`/users/${userId}/filter_by_ids`, {
      method: 'POST',
      body: JSON.stringify({ user_ids: ids })
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getPeopleIndex = () => {
  return new Promise((resolve, reject) => {
    apiFetch(`/users`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getLobbyUsers = () => {
  return new Promise((resolve, reject) => {
    apiFetch(`/users/lobby`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const updateUser = (id, data) => {
  return new Promise((resolve, reject) => {
    apiFetch(`/users/${id}`, {
      method: 'POST',
      body: JSON.stringify(data)
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getGenres = () => {
  return new Promise((resolve, reject) => {
    apiFetch('/genres')
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getInstruments = () => {
  return new Promise((resolve, reject) => {
    apiFetch('/instruments')
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

// export const getCurrentUser = () => {
//   return new Promise((resolve, reject) => {
//     apiFetch('/me')
//     .then(response => resolve(response))
//     .catch(error => reject(error))
//   })
// }

export const getFriends = userId => {
  return new Promise((resolve, reject) => {
    apiFetch(`/users/${userId}/friends`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const addFriend = (userId, friendId) => {
  return new Promise((resolve, reject) => {
    apiFetch(`/users/${userId}/friend_requests`, {
      method: 'POST',
      body: JSON.stringify({ friend_id: friendId })
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const removeFriend = (userId, friendId) => {
  return new Promise((resolve, reject) => {
    apiFetch(`/users/${userId}/friends/${friendId}`, {
      method: 'DELETE'
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getTextMessages = (options = {}) => {
  return new Promise((resolve, reject) => {
    apiFetch(`/text_messages?${new URLSearchParams(options)}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const createTextMessage = options => {
  return new Promise((resolve, reject) => {
    apiFetch(`/text_messages`, {
      method: 'POST',
      body: JSON.stringify(options)
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const createLobbyChatMessage = options => {
  return new Promise((resolve, reject) => {
    apiFetch(`/chat`, {
      method: 'POST',
      body: JSON.stringify(options)
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getNotifications = (userId, options = {}) => {
  return new Promise((resolve, reject) => {
    apiFetch(`/users/${userId}/my_notifications?${new URLSearchParams(options)}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const readNotifications = (userId) => {
  return new Promise((resolve, reject) => {
    apiFetch(`/users/${userId}/my_notifications/read`, {
      method: 'POST'
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const acceptFriendRequest = (userId, options = {}) => {
  return new Promise((resolve, reject) => {
    const { status, friend_request_id } = options;
    apiFetch(`/users/${userId}/friend_requests/${friend_request_id}`, {
      method: 'POST',
      body: JSON.stringify({ status })
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const deleteNotification = (userId, notificationId) => {
  return new Promise((resolve, reject) => {
    apiFetch(`/users/${userId}/notifications/${notificationId}`, {
      method: 'DELETE'
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getSessions = () => {
  return new Promise((resolve, reject) => {
    apiFetch(`/sessions`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getSessionsHistory = (options = {}) => {
  return new Promise((resolve, reject) => {
    apiFetch(`/sessions/history?${new URLSearchParams(options)}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getLatencyToUsers = (currentUserId, participantIds) => {
  return new Promise((resolve, reject) => {
    const query = participantIds.map(id => `user_ids[]=${id}`).join('&');
    apiFetch(`/users/${currentUserId}/latencies?${query}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getLobbyChatMessages = (options = {}) => {
  return new Promise((resolve, reject) => {
    console.log('getLobbyChatMessages', options);
    apiFetch(`/chat?${new URLSearchParams(options)}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getCountries = () => {
  return new Promise((resolve, reject) => {
    apiFetch(`/countries`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getRegions = countryId => {
  return new Promise((resolve, reject) => {
    apiFetch(`/regions?country=${countryId}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getCities = (countryId, regionId) => {
  return new Promise((resolve, reject) => {
    apiFetch(`/cities?country=${countryId}&region=${regionId}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const postUpdateAccountEmail = (userId, options) => {
  const { email, current_password } = options;
  return new Promise((resolve, reject) => {
    apiFetch(`/users/${userId}/update_email`, {
      method: 'POST',
      body: JSON.stringify({ update_email: email, current_password })
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const postUpdateAccountPassword = (userId, options) => {
  const { new_password, current_password } = options;
  return new Promise((resolve, reject) => {
    apiFetch(`/users/${userId}/set_password`, {
      method: 'POST',
      body: JSON.stringify({ old_password: current_password, new_password, new_password_confirm: new_password })
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const requestPasswordReset = userId => {
  return new Promise((resolve, reject) => {
    apiFetch(`/users/${userId}/request_reset_password`, {
      method: 'POST'
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const resetPassword = email => {
  return new Promise((resolve, reject) => {
    apiFetch(`/users/reset_password`, {
      method: 'POST',
      body: JSON.stringify({ email })
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const requstResetForgotPassword = email => {
  return new Promise((resolve, reject) => {
    apiFetch(`/request_reset_forgot_password`, {
      method: 'POST',
      body: JSON.stringify({ email })
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  }); 
};

export const resetForgotPassword = (options = {}) => {
  return new Promise((resolve, reject) => {
    apiFetch(`/reset_forgot_password`, {
      method: 'POST',
      body: JSON.stringify(options)
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const postUserAppInteraction = (userId, options) => {
  return new Promise((resolve, reject) => {
    apiFetch(`/users/${userId}/app_interactions`, {
      method: 'POST',
      body: JSON.stringify(options)
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getSubscription = () => {
  return new Promise((resolve, reject) => {
    apiFetch('/recurly/get_subscription')
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const changeSubscription = plan_code => {
  const options = { plan_code };
  return new Promise((resolve, reject) => {
    apiFetch('/recurly/change_subscription', {
      method: 'POST',
      body: JSON.stringify(options)
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getInvoiceHistory = (options = {}) => {
  return new Promise((resolve, reject) => {
    apiFetch(`/recurly/invoice_history?${new URLSearchParams(options)}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const createAffiliatePartner = options => {
  return new Promise((resolve, reject) => {
    apiFetch('/affiliate_partners', {
      method: 'POST',
      body: JSON.stringify(options)
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getAffiliatePartnerData = userId => {
  return new Promise((resolve, reject) => {
    apiFetch(`/users/${userId}/affiliate_partner`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getAffiliateSignups = (options = {}) => {
  if (!options.per_page) {
    options.per_page = 10;
  }
  if (!options.page) {
    options.page = 0;
  }
  return new Promise((resolve, reject) => {
    apiFetch(`/affiliate_partners/signups?${new URLSearchParams(options)}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getAffiliatePayments = () => {
  return new Promise((resolve, reject) => {
    apiFetch(`/affiliate_partners/payments`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const postAffiliatePartnerData = (userId, params) => {
  return new Promise((resolve, reject) => {
    apiFetch(`/users/${userId}/affiliate_partner`, {
      method: 'POST',
      body: JSON.stringify(params)
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const autocompleteJamTracks = (input, limit) => {
  const query = { match: input, limit };
  return new Promise((resolve, reject) => {
    apiFetch(`/jamtracks/autocomplete?${new URLSearchParams(query)}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getPurchasedJamTracks = (options = {}) => {
  options = { ...options, show_purchased_only: true };
  return new Promise((resolve, reject) => {
    apiFetch(`/jamtracks/purchased?${new URLSearchParams(options)}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getJamTrackArtists = (options = {}) => {
  return new Promise((resolve, reject) => {
    apiFetch(`/jamtracks/artists?${new URLSearchParams(options)}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getJamTracks = (options = {}) => {
  return new Promise((resolve, reject) => {
    apiFetch(`/jamtracks?${new URLSearchParams(options)}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getJamTrack = options => {
  const { id, ...rest } = options;
  return new Promise((resolve, reject) => {
    apiFetch(`/jamtracks/${id}?${new URLSearchParams(rest)}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const addJamtrackToShoppingCart = (options = {}) => {
  return new Promise((resolve, reject) => {
    apiFetch(`/shopping_carts/add_jamtrack`, {
      method: 'POST',
      body: JSON.stringify(options)
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getAppFeatures = (env) => {
  return new Promise((resolve, reject) => {
    apiFetch(`/app_features?env=${env}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export const getShoppingCart = () => {
  return new Promise((resolve, reject) => {
    apiFetch(`/shopping_carts`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const removeShoppingCart = (options = {}) => {
  return new Promise((resolve, reject) => {
    apiFetch(`/shopping_carts`, {
      method: 'DELETE',
      body: JSON.stringify(options)
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getBillingInfo = () => {
  return new Promise((resolve, reject) => {
    apiFetch(`/recurly/billing_info`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const createRecurlyAccount = (options = {}) => {
  return new Promise((resolve, reject) => {
    apiFetch(`/recurly/create_account`, {
      method: 'POST',
      body: JSON.stringify(options)
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const placeOrder = () => {
  return new Promise((resolve, reject) => {
    apiFetch(`/recurly/place_order`, {
      method: 'POST'
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const postUserEvent = (options = {}) => {
  return new Promise((resolve, reject) => {
    apiFetch(`/users/event/record`, {
      method: 'POST',
      body: JSON.stringify(options)
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const userOpenedJamTrackWebPlayer = () => {
  return new Promise((resolve, reject) => {
    apiFetch(`/users/progression/opened_jamtrack_web_player`, {
      method: 'POST'
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const markMixdownActive = options => {
  const { id, ...rest } = options;
  return new Promise((resolve, reject) => {
    apiFetch(`/jamtracks/${id}/mixdowns/active`, {
      method: 'POST',
      body: JSON.stringify(rest)
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export const createMixdown = options => {
  return new Promise((resolve, reject) => {
    apiFetch(`/mixdowns`, {
      method: 'POST',
      body: JSON.stringify(options)
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export const deleteMixdown = id => {
  return new Promise((resolve, reject) => {
    apiFetch(`/mixdowns/${id}`, {
      method: 'DELETE'
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export const updateAvatar = options => {
  const { id, ...rest } = options;
  const opts = {
    url: rest['url'],
    // crop_selection: rest['crop_selection']
  };
  return new Promise((resolve, reject) => {
    apiFetch(`/users/${id}/avatar_v2`, {
      method: 'POST',
      body: JSON.stringify(opts)
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export const deleteAvatar = id => {
  return new Promise((resolve, reject) => {
    apiFetch(`/users/${id}/avatar_v2`, {
      method: 'DELETE'
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}