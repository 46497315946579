import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../context/UserAuth';
import { toast } from 'react-toastify';
import { useShoppingCart } from '../../hooks/useShoppingCart';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import JKTooltip from '../common/JKTooltip';
import { placeOrder, updateUser } from '../../helpers/rest';

const JKJamTrackPurchaseButton = ({ jamTrack }) => {
  const history = useHistory();
  const { currentUser } = useAuth();
  const { addCartItem, getCartItems, hasOnlyFreeItemsInShoppingCart } = useShoppingCart();
  const { t } = useTranslation('jamtracks');

  const addToCart = async () => {
    if (!currentUser) {
      return;
    }
    const options = {
      id: jamTrack.id,
      variant: 'full'
    };

    try {
      const resp = await addCartItem(options);
      console.log('resp1', resp);
      if(resp.fast_reedem){ //if this is a free jamtrack
        //get shopping cart items and see if all are free
        if(!hasOnlyFreeItemsInShoppingCart()){
          history.push('/jamtracks');
        }else{
          const purchadeResp = await placeOrder();
          console.log('resp2', purchadeResp);
          if(purchadeResp.ok){
            const userResp = await updateUser(currentUser.id);
            console.log('resp3', userResp);
            if(userResp.ok){
              history.push('/checkout/success?free=yes&jamtrackId=' + jamTrack.id);
            }
          }
        }
        
      }else{ //if this is a paid jamtrack
        toast.success(t('search.list.add_success_alert'));
        history.push('/shopping-cart');
      }
    } catch (error) {
      console.log(error);
      toast.error(t('search.list.add_error_alert'));
      
    }
   
  };

  return (
    <>
      {jamTrack.purchased ? (
      
          <span>{t('search.list.purchased')}</span>
       
      ) : jamTrack.allow_free && currentUser && currentUser.show_free_jamtrack ? (
        <>
          <Button color="primary" onClick={addToCart} size="sm" className="mr-1">
            {t('search.list.get_it_free')}
          </Button>
          <JKTooltip title={t('search.list.get_it_free_help_text')} />
        </>
      ) : jamTrack.added_cart ? (
        <Link to="/shopping-cart">{t('search.list.already_in_cart')}</Link>
      ) : (
        <>
          <div className="fs-1">$ {jamTrack.download_price}</div>
          <Button color="primary" size="sm" className="mr-1" onClick={addToCart}>
            {t('search.list.add_to_cart')}
          </Button>
        </>
      )}
    </>
  );
};

JKJamTrackPurchaseButton.propTypes = {
  jamTrack: PropTypes.object.isRequired
};

export default JKJamTrackPurchaseButton;
