import React from 'react';
import { Table } from 'reactstrap';
import JKAffiliateEarning from './JKAffiliateEarning';
import { useTranslation } from 'react-i18next';

const JKAffiliateEarningsList = ({ payments }) => {
  const { t } = useTranslation('affiliate');
  return (
    <Table striped bordered className="fs--1" data-testid="affiliateEarningsList">
      <thead>
        <tr>
          <th>{t('earnings.list.header.date')}</th>
          <th className='text-center'>{t('earnings.list.header.subscriptions')}</th>
          <th className='text-center'>{t('earnings.list.header.jamtracks')}</th>
          <th className='text-center'>{t('earnings.list.header.earnings')}</th>
        </tr>
      </thead>
      <tbody>
        {payments.map((payment, index) => (
          <JKAffiliateEarning key={`${payment.year}-${payment.month}`} payment={payment} /> 
        ))}
      </tbody>
    </Table>
  );
};

export default JKAffiliateEarningsList;
